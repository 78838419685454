import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Nav.scss";

const openNavStyles = {
  menu: { zIndex: 6, visibility: "visible", opacity: 1 },
};

const LINKS = [
  {
    href: "/",
    label: "Home",
  },
  {
    href: "/gigs",
    label: "Gigs",
  },
  {
    href: "/vinyl",
    label: "Vinyl",
  },
  {
    href: "/shop",
    label: "Shop",
  },
  {
    href: "/blog",
    label: "Blog",
  },
];

const isCurrentPage = (href, location) =>
  href === location.pathname ? true : false;

function Nav() {
  const [openNav, updateOpenNav] = useState(false);
  const location = useLocation();

  return (
    <>
      <div className="nav mobile-nav" style={openNav ? openNavStyles.menu : {}}>
        <button className="close" onClick={() => updateOpenNav(false)}>
          <div className="close-slice"></div>
          <div className="close-slice"></div>
        </button>
        <div className="menu-nav-container">
          <ul id="primary-menu" className="nav-menu">
            {LINKS.map((link) => {
              const isSelected = isCurrentPage(link.href, location)
                ? "--selected"
                : "";

              return (
                <li key={link.href} className={`menu-item ${isSelected}`}>
                  <Link to={link.href}> {link.label} </Link>
                </li>
              );
            })}
          </ul>
        </div>{" "}
      </div>
      <nav id="site-navigation" className="nav main-navigation">
        <div className="menu-nav-container">
          <ul id="primary-menu" className="menu">
            {LINKS.map((link) => {
              const isSelected = isCurrentPage(link.href, location)
                ? "--selected"
                : "";
              return (
                <li key={link.href} className={`menu-item ${isSelected}`}>
                  <Link to={link.href}> {link.label} </Link>
                </li>
              );
            })}
          </ul>
        </div>{" "}
        <button className="hamburger" onClick={() => updateOpenNav(true)}>
          <div className="slice"></div>
          <div className="slice"></div>
          <div className="slice"></div>
        </button>
      </nav>
    </>
  );
}

export default Nav;
