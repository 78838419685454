import SocialMedia from "../SocialMedia/SocialMedia";
import "./Footer.scss";

const d = new Date();
let year = d.getFullYear();

const Footer = () => {
  return (
    <footer className="footer">
      <div className="row footer-wrapper">
        <div className="social-media-footer col-12">
          <SocialMedia />
        </div>
        <h5 className="copyright col-12">
          © <span className="wrest">wrest</span> {year}
        </h5>
      </div>
    </footer>
  );
};

export default Footer;
