import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../modules/contentful";
import Nav from "../../components/Nav/Nav";
import Video from "../../components/Video/Video";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import "./Home.scss";

function Home() {
  const [pageData, updatePageData] = useState({});
  const [loaded, updatedLoaded] = useState(false);

  const getPageData = async () => {
    const page = await apiClient.getHomePage();
    updatePageData(page.header.fields);
    updatedLoaded(true);
  };

  useEffect(() => {
    getPageData();
  }, []);

  return loaded ? (
    <div>
      <header id="masthead" className="site-header main-font --home">
        <Nav />
      </header>
      <article>
        <header className="entry-header">
          <div id="content-header-container">
            <div id="content-header-wrapper">
              <div id="video-wrapper">
                <div id="content-header-video">
                  <Video
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload=""
                    src={pageData.backgroundVideo.fields.file.url}
                    type={pageData.backgroundVideo.fields.file.contentType}
                  />
                </div>
              </div>
              <div id="text-wrapper">
                <div id="content-header-text">
                  <h1 className="wrest">{pageData.heading} </h1>
                  <Link to={pageData.ctaLink} className="tagline --link">
                    {pageData.ctaText}
                  </Link>
                  <div className="home__section">
                    <div className="social-media">
                      <SocialMedia />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </article>
    </div>
  ) : (
    <div></div>
  );
}

export default Home;
