import PropTypes from "prop-types";

const Video = ({ autoPlay, loop, muted, playsInline, preload, src, type }) => {
  return (
    <video
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
      preload={preload}
    >
      <source src={src} type={type} />
    </video>
  );
};

Video.propTypes = {
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
  preload: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
};

export default Video;
