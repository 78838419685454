const contentful = require("contentful");

const apiClient = () => {
  return contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: process.env.REACT_APP_API_SPACE_ID,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: process.env.REACT_APP_API_ACCESS_TOKEN,
    environment: process.env.REACT_APP_API_ENVIRONMENT_ID, // defaults to 'master' if not set
  });
};

const getContentType = (contentTypeId, options = {}) => {
  return apiClient()
    .getContentType(contentTypeId)
    .then((result) => result);
};

const getEntries = (contentTypeId, options = {}) => {
  return apiClient()
    .getEntries({
      content_type: contentTypeId,
      ...options,
    })
    .then((result) => result);
};

const getEntry = (entryId) => {
  return apiClient()
    .getEntry(entryId)
    .then((result) => result);
};

const getGigs = async () => {
  const gigs = await getEntries("gigs", { limit: 500 });
  return gigs.items.map((item) => item.fields);
};

const getHomePage = async () => {
  const pageData = await getEntry("4uUouPdp6arLlShf6o7h2C");
  return pageData.fields;
};

const getGigsPage = async () => {
  const pageData = await getEntry("2cFN17MXQff6OJxYnnPshC");
  return pageData.fields;
};

const api = {
  apiClient,
  getContentType,
  getHomePage,
  getGigsPage,
  getGigs,
};

export default api;
