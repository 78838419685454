import Grid from "@mui/material/Grid";
import PushPinIcon from "@mui/icons-material/PushPin";
import "./Gig.scss";

const Gig = ({ gigData, pinnedGig }) => {
  const { venue, date, soldOut, ticketLink, city, title } = gigData;
  const addComma = (venue && !city) || (!venue && city) ? "" : ", ";
  const d = new Date(date);
  const formattedDate = `${d.getDate()}/${
    d.getMonth() + 1
  }/${d.getFullYear()}  ${d.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;

  return (
    <Grid container className={`gig-container ${pinnedGig ? "pinned" : ""}`}>
      {pinnedGig && (
        <div className="pinned-gig-icon">
          <PushPinIcon />
        </div>
      )}
      <Grid item xs={12} sm={4}>
        <h4 className="gig-meta gig__title">{title}</h4>
      </Grid>
      <Grid item xs={12} sm={3}>
        {!venue && !city ? (
          <h5 className="gig-meta gig__venue-location"> TBC </h5>
        ) : (
          <h5 className="gig-meta gig__venue-location">
            {" "}
            {venue}
            {addComma} {city}{" "}
          </h5>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <h5 className="gig-meta gig__date-time">{formattedDate}</h5>
      </Grid>
      <Grid item xs={12} sm={2} className="gig__tickets">
        {soldOut ? (
          <h5 className="gig-meta gig__tickets-sold-out --bold col-12 col-md-6 col-lg-2">
            <span className="sold-out"> **SOLD OUT** </span>
          </h5>
        ) : new Date(date) <= new Date() ? (
          ""
        ) : (
          <a
            className="gig-meta gig__tickets-href --bold"
            href={ticketLink}
            target="_blank"
            rel="noreferrer"
          >
            Tickets
          </a>
        )}
      </Grid>
    </Grid>
  );
};

export default Gig;
