import Container from "@mui/material/Container";
import Nav from "../../Nav/Nav";
import Footer from "../../Footer/Footer";
import "./GenericPageTemplate.scss";

const GenericPageTemplate = ({ children }) => {
  return (
    <div style={{ background: "#0c0d0c" }}>
      <div>
        <Nav />
      </div>
      <Container>{children}</Container>
      <Footer />
    </div>
  );
};

export default GenericPageTemplate;
