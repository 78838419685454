import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/Home/Home";
import Gigs from "../pages/Gigs/Gigs";

const Redirect = ({ redirectPath }) => {
  useEffect(() => {
    return window.location.replace(redirectPath);
  });

  return <></>;
};

/**
 * should mtch the _redirects file
 * this handles requests from within the app router e.g. clicking a <link> element / clicking a naavbar link
 * when the url is hit on the server directly _redirects takes care of redirecting
 */
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/gigs",
    element: <Gigs />,
  },
  {
    path: "/shop",
    element: <Redirect redirectPath="https://wrestband.myshopify.com" />,
  },
  {
    path: "/blog",
    element: (
      <Redirect redirectPath="https://wrestband.myshopify.com/blogs/news" />
    ),
  },
  {
    path: "/vinyl",
    element: (
      <Redirect redirectPath="https://wrest.bandcamp.com/album/end-all-the-days" />
    ),
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
