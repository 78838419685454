import { useState, useEffect, useCallback } from "react";
import apiClient from "./../../modules/contentful";
import GenericPageTemplate from "../../components/Templates/GenericPageTemplate/GenericPageTemplate";
import Gig from "../../components/Gig/Gig";
import Image from "../../components/Image/Image";
import "./Gigs.scss";

/*
Bit of a nonsense. When adding to the content model in Contentful
property key doesn't back fill to appear in the api response automatically
of any entries that already exist. This may just be for BOOL type.
*/
const getSafeKey = (key, object) => {
  const hasKey = object.hasOwnProperty(key);
  if (hasKey) return object[key];
  else return false;
};

function Gigs() {
  const [pageData, updatePageData] = useState({});
  const [loaded, updatedLoaded] = useState(false);
  const [gigs, updateGigs] = useState({
    pinnedGigs: [],
    currentGigs: [],
    pastGigs: [],
  });
  const getGigs = async () => {
    const gigs = await apiClient.getGigs();
    if (gigs.length) {
      const now = new Date();
      const sortedGigs = gigs.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      const currentGigs = sortedGigs.filter(
        (gig) => new Date(gig.date) >= now && !getSafeKey("pinnedGig", gig)
      );

      const pinnedGigs = sortedGigs.filter(
        (gig) => new Date(gig.date) >= now && getSafeKey("pinnedGig", gig)
      );

      const pastGigs = sortedGigs.filter((gig) => new Date(gig.date) < now);

      const sortedCurrentGigs = currentGigs.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      const sortedPinnedGigs = pinnedGigs.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      updateGigs({
        pinnedGigs: sortedPinnedGigs,
        currentGigs: sortedCurrentGigs,
        pastGigs,
      });
    }
  };

  const getPageData = async () => {
    const page = await apiClient.getGigsPage();
    updatePageData(page.header.fields);
  };

  const loadData = useCallback(async () => {
    await getGigs();
    await getPageData();
    updatedLoaded(true);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return loaded ? (
    <GenericPageTemplate>
      <div className="gigs__image">
        <Image src={pageData.image.fields.file.url} alt="paint brush stroke" />
      </div>
      <h1 className="heading__gigs --brand"> {pageData.heading} </h1>
      {gigs.pinnedGigs.map((gig) => {
        return (
          <Gig
            key={`${gig.date}-${gig.title}`}
            gigData={gig}
            pinnedGig={true}
          />
        );
      })}

      {gigs.currentGigs.map((gig) => {
        return <Gig key={`${gig.date}-${gig.title}`} gigData={gig} />;
      })}
      <h2 className="heading__previous-gigs --brand"> Previous Gigs</h2>
      {gigs.pastGigs.map((gig) => {
        return <Gig key={`${gig.date}-${gig.title}`} gigData={gig} />;
      })}
    </GenericPageTemplate>
  ) : (
    <></>
  );
}

export default Gigs;
